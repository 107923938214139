import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function createConsumerMessage(requestBody) {
    try {
        const res = await http.post(`/message/trigger_message`, { requestBody });
        console.log({ status: API_STATES.success, data: res })
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function scheduleUserBasedNotification(requestBody){
    try {
        const res = await http.post(`/message/schedule_notification/user_based`, requestBody);
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getNotificationHistory(type) {
    try {
        const res = await http.get(`/message/messages_history/${type}`);
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function terminateMessage(requestBody) {
    try {
        const res = await http.post(`/message/terminate_userbased_messages`, { requestBody });
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getNotificationDetail(notificationId) {
    try {
        const res = await http.get(`/message/userbased_messages/${notificationId}`);
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editAllMessages(requestBody){
    try {
        const res = await http.post(`/message/edit_userbased_messages`, { requestBody });
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function editSingleUserMessage(requestBody){
    try {
        const res = await http.post(`/message/edit_userbased_message`, { requestBody });
        return { status: API_STATES.success, data: res };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
