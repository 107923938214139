import API_STATES from '@/constants/StateConstants';

/**
 * A do-nothing function that can be passed in to the handleApiError to
 * do nothing in the case that the caller has no state to set or no statusValue
 * to set.
 */
export function noOp() {}

export function handleApiError(
    error, // error http response code value
    setStatusValue, // run to set the error status
    setStatusMessage, // run to set the error message, usually a useState setter
    http404ErrorMessage = 'Item(s) not found.',
    defaultErrorMessage = 'Unknown error'
) {
    setStatusValue(API_STATES.error);
    const code = error?.response?.data?.code
    let message = error?.response?.data?.message;
    switch(code) {
        case 'invalid_parameter_value':
            message = `Invalid value or parameter ${message}`;
            break;
        case 'missing_required_parameter':
            message = `Missing required parameter ${message} in api`;
            break;
        case 'customer_on_credit_hold':
            message = `Account on credit hold, unable to process orders.`;
            break;
        default:
            message = message ?? code
            break;
    }
    if(message) {
        setStatusMessage(message);
        return message;
    }
    switch (error?.response?.status ?? error) {
        case 401:
            message = 'CSR is not logged in.';
            break;
        case 403:
            message = 'Unauthorized to perform this action';
            break;
        case 422:
            message = 'Unprocessable/Invalid Input';
            break;
        case 404:
            message = http404ErrorMessage;
            break;
        case 409:
            message = 'Duplicate Entry Found';
            break;
        case 402:
            message = 'Credit Limit Exceeded!';
            break;
        default:
            message = defaultErrorMessage;
            break;
    }
    setStatusMessage(message);
    return message;
}
