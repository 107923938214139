import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { getDemoAccountsReport } from '@/services/api/report.service';
import { useEffect, useMemo, useState } from 'react';
import { handleApiError } from '../General/HandleApiError';
import DataTable, { FILTER_TYPES } from '../General/DataTable';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { Link } from 'react-router-dom';

export function DemoAccountsReport() {
    const [reportApiStatus, setReportApiStatus] = useState(API_STATES.none);
    const [reportApiError, setReportApiError] = useState('');
    const [reportResult, setReportResult] = useState([]);

    async function loadDemoAccounts() {
        setReportApiStatus(API_STATES.loading);
        const result = await getDemoAccountsReport();
        switch (result.status) {
            case API_STATES.success:
                setReportApiStatus(API_STATES.success);
                setReportResult(result.data);
                break;
            case API_STATES.error:
                handleApiError(result.error, setReportApiStatus, setReportApiError);
                break;
            default:
                setReportApiStatus(API_STATES.none);
                setReportApiError('');
        }
    }

    const demoAccountsReportColumns = useMemo(
        () => [
            {
                Header: 'Company Name',
                accessor: 'name',
                className: generalStyles.tableCell,
                Cell: ({ row, value }) => (
                    <Link className={generalStyles.link} to={`/newcompanypage/${row.original.nsCompanyId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                Header: 'Email Address',
                /**
                 *
                 * @param {Object} row
                 * @param {string[]} row.userEmails
                 * @returns
                 */
                accessor: (row) => row.userEmails?.join(', \n') ?? '',
                id: 'email',
                className: generalStyles.tableCell,
            },
            {
                Header: 'Template',
                /**
                 * @param {Object} row
                 * @param {string} row.template
                 * @returns {string}
                 */
                accessor: (row) => row.template?.replaceAll('_', ' '),
                id: 'template',
                className: generalStyles.tableCell,
                Filter: MultiSelectColumnFilter,
                filter: FILTER_TYPES.multiple,
            },
            {
                Header: 'Date Created',
                accessor: (row) => new Date(row.createdOn)?.toLocaleDateString(),
                id: 'createdOn',
                className: generalStyles.tableCell,
            },
        ],
        []
    );

    useEffect(() => {
        loadDemoAccounts();
    }, []);

    return (
        <>
            <h1>Demo Accounts</h1>
            <hr />
            <div data-test="generate-report-status">
                {reportApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {reportApiError}</p>
                ) : reportApiStatus === API_STATES.loading ? (
                    <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    reportApiStatus === API_STATES.success && (
                        <DataTable
                            data={reportResult}
                            columns={demoAccountsReportColumns}
                            downloadFileName={'DemoAccountsReport'}
                            withDownload
                        />
                    )
                )}
            </div>
        </>
    );
}
