import React, { useState, useEffect } from 'react'
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import styles from '@/components/UserLevelReport/UserLevelReport.module.scss'
import DataTable from '@/components/General/DataTable';
import { COLUMNS } from './Columns'
import ByzzerSwitch from '@/components/General/ByzzerSwitch';
import { getUserLevelReportData } from '@/services/api/report.service';

const UserLevelReport = () => {
    const [activeContractStatus, setActiveContractStatus] = useState(true)
    const [usersData, setUsersData ]= useState([])
    const [getUsersDataApiStatus, setGetUsersDataApiStatus] = useState(API_STATES.none)
    const [getUserDataApiError, setGetUsersDataApiError] = useState('')

    const IsParsable = (data) => {
        try {
              JSON.parse(data);
             } catch (e) {
              return false;
            }
         return true;
    }
    function formattedCharacteristics(char){
        if(IsParsable(char)){
            let getCharData = JSON.parse(char)?.map((item) => {
                if (item?.characteristic) {
                    return `${item?.characteristic} ${item?.condition} ${' ' + item?.value + ', '}`;
                } else {
                    return '-';
                }})
                return getCharData
        } else {
           return char.replace(/[{\[\]'"}]+/g, '').replaceAll(',', ', ')
        }
    }
    function formattedSelections(displayData) {
        return displayData ? displayData.replace(/[{\[\]'"}]+/g, '').replaceAll(',', ', ') : ''
    }
    useEffect(()=>{
        ;(async()=>{
            setGetUsersDataApiStatus(API_STATES.loading)
            const response = await getUserLevelReportData(activeContractStatus)
            if(response.status ===  API_STATES.success) {
                const formattedData = response.data.map((row)=>{
                    return {
                        emailId: row.email,
                        nsCompanyId: row.nsCompanyId,
                        companyName: row.company_name,
                        role: row.role,
                        numberOfAlertsAllowed: row.numberOfAlertsAllowed,
                        numberOfAlertsSetup: row.numberOfAlertsSetup,
                        alertsSetup: row.alertsSetup,
                        brands: formattedSelections(row.brands),
                        categories: formattedSelections(row.categories),
                        characteristicFilters: formattedCharacteristics(row.characteristicFilters),
                        timePeriod: row.timePeriod,
                        markets: row.markets,
                        hasAcceptedTcs: row.hasAcceptedTcs,
                        homePageNotifications: row.homePageNotifications.split(', '),
                        dataRefreshAlert: row.dataRefreshAlert
                    }
                })
                setUsersData(formattedData)
                setTimeout(() => {
                    setGetUsersDataApiStatus(API_STATES.none)
                }, 3000)

            }else {
                handleApiError(
                    response.error,
                    setGetUsersDataApiStatus,
                    setGetUsersDataApiError
                );
                setTimeout(() => setGetUsersDataApiStatus(API_STATES.none), 6000)
            }
        })()
    }, [activeContractStatus])

    return (
        <>
            <h1>User Level Report</h1>
            <hr />
            <div className={styles.rowFlex}>

                <label>Show Active Contracts Only</label>
                <ByzzerSwitch
                    data-test="active-contracts-switch"
                    onChange={() => setActiveContractStatus(!activeContractStatus)}
                    value={activeContractStatus ?? true}
                />
            </div>
            <div data-test="generate-report-status">
                {getUsersDataApiStatus === API_STATES.error ? (
                    <p className={generalStyles.errorMessage}>Error: {getUserDataApiError}</p>
                ) : getUsersDataApiStatus === API_STATES.loading ? (
                        <p className={generalStyles.statusMessage}>Loading...</p>
                ) : (
                    <>
                        <DataTable data={usersData} withDownload columns={COLUMNS} downloadFileName={"UserLevelReport"} noDataDescription='No data found to display users information'/>
                    </>
                )}
            </div>

        </>
    )
}

export default UserLevelReport