import React, { useState, useRef, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '@images/icons/calendar.svg';
import moment from 'moment';
import clockIcon from '@images/icons/clock.svg';
import TimeKeeper from 'react-timekeeper';
import styles from '../HomepageNotification.module.scss';
import generalStyles from '@/components/General/Styles.module.scss';
import Select from 'react-select';
import * as Constant from '../Constants';
import API_STATES from '@/constants/StateConstants';
import { getMarkets } from '@/services/api/reference.service';
import * as MessageService from '@/services/api/marketBasedNotification.service';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ByzzerDateRange from '@/components/ByzzerDateRange';
import { format } from 'date-fns';
import { getMessageTypeValue, getRunTypeValue, getMarketNotificationTypeValue } from '../Utils';
import { handleApiError } from '@/components/General/HandleApiError';

function MarketBasedNotificationForm({
    refresh,
    selectedNotification,
    edit = false,
    editSingleNotification = false,
    closeEdit,
}) {
    const [endDate, setEndDate] = useState(edit ? moment(new Date(selectedNotification.endDate)) : null);
    const [selectedNotificationType, setSelectedNotificationType] = useState(
        edit ? getMessageTypeValue(selectedNotification.notificationType) : []
    );
    const [notificationMessage, setNotificationMessage] = useState(edit ? selectedNotification.message : '');
    const [notificationHeader, setNotificationHeader] = useState(edit ? selectedNotification.header : '');
    const [notificationLink, setNotificationLink] = useState(edit ? selectedNotification.hyperLink : '');
    const [createApiState, setCreateApiState] = useState(API_STATES.none);
    const [createApiError, setCreateApiError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openScheduleNotificationModal, setOpenScheduleNotificationModal] = useState(false)
    const [markets, setMarkets] = useState([]);
    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [triggerType, setTriggerType] = useState(edit ? getRunTypeValue(selectedNotification.runType) : []);
    const [clearDateRange, setClearDateRange] = useState(false);
    const dateRangeRef = useRef();

    const [scheduledDate, setScheduledDate] = useState(null);
    const [time, setTime] = useState(moment()); // buffer to store new eta

    const [showTimePicker, setShowTimePicker] = useState(false);

    const [updateScheduledDateApiState, setUpdateScheduledDateApiState] = useState(API_STATES.none);
    const [updateScheduledDateApiError, setScheduledDateApiError] = useState('');

    const [removeScheduledDateApiState, setRemoveScheduledDateApiState] = useState(API_STATES.none);
    const [removeScheduledDateApiError, setRemoveScheduledDateApiError] = useState('');

    const [userCount, setUserCount] = useState(0);
    
    const [marketsApiState, setMarketsApiState] = useState(API_STATES.none);
    const [marketsApiError, setMarketsApiError] = useState(null);
    const timeRangeRef = useRef();
    const [marketNotificationType, setMarketNotificationType] = useState(
        edit ? getMarketNotificationTypeValue(selectedNotification.marketNotificationType) : []
    );

    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultEndDate, setDefaultEndDate] = useState(null);
    const transformMarkets = (accessibleMarkets) => {
        const accessibleMarketList =
            accessibleMarkets?.map((market) => {
                return { label: market.marketName, value: market.marketName };
            }) ?? [];
        return accessibleMarketList;
    };

    async function loadMarkets() {
        setMarketsApiState(API_STATES.loading);

        const accessibleMarket = await getMarkets();
        if (accessibleMarket.status === API_STATES.success) {
            // Require to format the list to display as select option
            const marketlist = transformMarkets(accessibleMarket.data ?? []);
            setMarkets(marketlist);
            setMarketsApiState(API_STATES.success);
            if (edit) {
                const list = marketlist.filter((item) => selectedNotification.marketList.includes(item.value));
                setSelectedMarkets(list);
                onChangeDateRange(selectedNotification.fromDate, selectedNotification.toDate);
            }
        } else {
            const error = accessibleMarket.error;
            setMarketsApiError(error);
            setMarkets([]);
            setMarketsApiState(API_STATES.error);
        }
    }

    const selectMarkets = (selectedMarket) => {
        if (selectedMarket.some((company) => company.value === 'All')) {
            setSelectedMarkets(markets);
        } else {
            setSelectedMarkets(selectedMarket);
        }
    };

    const onDateChange = (endDateValue) => {
        if (endDateValue && endDateValue !== '') setEndDate(endDateValue);
        else setEndDate(null);
    };

    if (markets?.length === 0 && marketsApiState === API_STATES.none) {
        loadMarkets();
    }

    const clearSelections = () => {
        setSelectedMarkets([]);
        setTriggerType([]);
        setClearDateRange(true);
        setMarketNotificationType([]);
        setEndDate(null);
        setSelectedNotificationType([]);
        setNotificationHeader('');
        setNotificationLink('');
        setNotificationMessage('');
        setClearDateRange(false);
        timeRangeRef.current = null;
        setDefaultEndDate(null);
        setDefaultStartDate(null);
        setUserCount(0);
    };

    async function createRequestBody() {
        const expirationDate = endDate ? moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
        const runFrom = timeRangeRef?.current?.runFrom
            ? moment(new Date(timeRangeRef?.current?.runFrom)).startOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;
        const runTo = timeRangeRef?.current?.runTo
            ? moment(new Date(timeRangeRef?.current?.runTo)).endOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;

        const messageType =
            selectedNotificationType?.value ??
            selectedNotificationType?.[0]?.value ??
            selectedNotification?.notificationType ??
            Constant.NOTIFICATION_TYPE[1].value;

        const selectedTriggerType =
            triggerType?.value ??
            triggerType?.[0]?.value ??
            triggerType?.notificationType ??
            Constant.RUN_TYPE[1].value;
        const selectedMarketTriggerType =
            marketNotificationType?.value ?? marketNotificationType?.[0]?.value ?? Constant.NOTIFICATION_TYPE[1].value;

        let requestBody = {
            marketMessage: {
                markets: selectedMarkets?.map((obj) => obj.value),
                marketTriggerType: selectedTriggerType,
                startDtm: runFrom,
                endDtm: runTo,
                notificationType: selectedMarketTriggerType,
            },
            expirationDtm: expirationDate,
            notification: { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink },
            messageType: messageType,
            triggerType: Constant.CUSTOM,
            priority: Constant.PRIORITY.LOW,
            messageCreationType: Constant.MESSAGE_TYPE.MARKET_BASED,
        };
        if (edit) {
            requestBody.notificationId = selectedNotification.notificationId;
            requestBody.csrNotificationId = selectedNotification.csrNotificationId;
            requestBody.recordType = 'edit';
        }
        if (edit && editSingleNotification) {
            requestBody.id = selectedNotification.id;
            requestBody.recepientId = selectedNotification?.recepientId ?? null;
            requestBody.notificationId = selectedNotification.notificationId;
            requestBody.companyId = selectedNotification?.companyId ?? null;
            
            //TODO fix this - edit_userbased_message API accepts
            //'requestBody.message' instead of 'requestBody.notification'
            if(requestBody.marketMessage?.marketTriggerType === "reportBased") {
                requestBody.message = { message: notificationMessage, header: notificationHeader, hyperLink: notificationLink };
            }
        }
        return requestBody;
    }

    async function createScheduledNotificationRequestBody(scheduledDtm) {
        const expirationDate = endDate ? moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : null;
        const runFrom = timeRangeRef?.current?.runFrom
            ? moment(new Date(timeRangeRef?.current?.runFrom)).startOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;
        const runTo = timeRangeRef?.current?.runTo
            ? moment(new Date(timeRangeRef?.current?.runTo)).endOf('day').format('YYYY/MM/DD HH:mm:ss')
            : null;

        const messageType =
            selectedNotificationType?.value ??
            selectedNotificationType?.[0]?.value ??
            selectedNotification?.notificationType ??
            Constant.NOTIFICATION_TYPE[1].value;

        const selectedTriggerType =
            triggerType?.value ??
            triggerType?.[0]?.value ??
            triggerType?.notificationType ??
            Constant.RUN_TYPE[1].value;
        const selectedMarketTriggerType =
            marketNotificationType?.value ?? marketNotificationType?.[0]?.value ?? Constant.NOTIFICATION_TYPE[1].value;

        let requestBody = {
            criteria: {
                markets: selectedMarkets?.map((obj) => obj.value),
                marketTriggerType: selectedTriggerType,
                startDtm: runFrom,
                endDtm: runTo,
                notificationType: selectedMarketTriggerType,
            },
            messageDetails: {
                message: notificationMessage,
                header: notificationHeader,
                hyperLink: notificationLink,
                messageType,
                priority: Constant.PRIORITY.LOW
            },
            expirationDtm: expirationDate,
            scheduledDtm
        };
        return requestBody;
    }

    const sendNotification = async () => {
        try {
            setOpenModal(false);

            setCreateApiState(API_STATES.loading);
            //creating request body based on create or edit option
            let requestBody = await createRequestBody();
            let response;
            if (edit && editSingleNotification) {
                if(requestBody.categoryMessage?.categoryTriggerType === "reportBased") {
                    response = await MessageService.editSingleUserMessage(requestBody);
                } else {
                    response = await MessageService.editCompanyBasedMessage(requestBody);
                }
            } else if (edit) {
                response = await MessageService.editAllMessages(requestBody);
            } else {
                response = await MessageService.createMarketBasedMessage(requestBody);
            }
            clearSelections();

            if (response.status === API_STATES.success) {
                setCreateApiState(API_STATES.success);
                refresh(true);
                if (edit) {
                    closeEdit();
                }
            } else {
                setCreateApiError(response.error);
                setCreateApiState(API_STATES.error);
            }
        } catch (e) {
            setCreateApiError(e);
            setCreateApiState(API_STATES.error);
        }
    };
    const onSubmitSchedulednotification = async (time) => {
        try {
            setOpenScheduleNotificationModal(false);

            setCreateApiState(API_STATES.loading);
            const scheduledDtm = time.toDate().toISOString()
            let requestBody = await createScheduledNotificationRequestBody(scheduledDtm);
            console.log("requestBody", requestBody)
            let response;
            response = await MessageService.scheduleMarketBasedNotification(requestBody);
            clearSelections();

            if (response.status === API_STATES.success) {
                setCreateApiState(API_STATES.success);
                refresh(true);
            } else {
                handleApiError(response.error, setCreateApiState, setCreateApiError)
            }
        } catch (e) {
            console.error('unexpected error in SubmitScheduledNotification', e)
            handleApiError(422, setCreateApiState, setCreateApiError)
        }
    };



    const timePickerRef = useRef(null); // To detect outer click

     const handleClickOutsideTimePicker = useCallback(
        (event) => {
            if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
                setShowTimePicker(false);
            }
        },
        [timePickerRef]
    );

    useEffect(() => {
        // Bind the event listener for click on outside time picker
        if (showTimePicker) {
            document.addEventListener('mousedown', handleClickOutsideTimePicker);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideTimePicker);
        }
    }, [handleClickOutsideTimePicker, showTimePicker]);

    const onScheduleNotification = async() =>{
        let requestBody = await createRequestBody();
        let response = await MessageService.getUserCount(requestBody);
        if (response.status === API_STATES.success) {
            setUserCount(response.data);
            setOpenScheduleNotificationModal(true);
        }
    }

    const updateScheduleDate=(time, newDate)=>{
        const dateTimeString = newDate + time.format(' hh:mm a');
        setTime(moment(dateTimeString, 'ddd MMM DD YYYY hh:mm a'));
    }

    const updateScheduleTime=(time, newTime)=> {
        const dateTimeString = time.format('DD/MM/YYYY ') + newTime.formatted12;
        setTime(moment(dateTimeString, 'DD/MM/YYYY hh:mm a'));
    };

    const displayPreview = async () => {
        let requestBody = await createRequestBody();
        let response = await MessageService.getUserCount(requestBody);
        if (response.status === API_STATES.success) {
            setUserCount(response.data);
            setOpenModal(true);
        }
        
    };

    const validateSelection = () => {
        if (triggerType?.value === 'reportBased' || triggerType[0]?.value === 'reportBased') {
            return !(
                selectedMarkets?.length !== 0 &&
                marketNotificationType?.length !== 0 &&
                triggerType?.length !== 0 &&
                timeRangeRef.current !== null &&
                timeRangeRef.current !== undefined &&
                timeRangeRef.current?.runFrom !== null &&
                timeRangeRef.current?.runFrom !== undefined &&
                timeRangeRef.current?.runTo !== null &&
                timeRangeRef.current?.runTo !== undefined &&
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader.trim() !== '' &&
                notificationMessage &&
                notificationMessage.trim() !== '' &&
                endDate
            );
        } else {
            return !(
                selectedMarkets?.length !== 0 &&
                marketNotificationType?.length !== 0 &&
                triggerType?.length !== 0 &&
                selectedNotificationType?.length !== 0 &&
                notificationHeader &&
                notificationHeader.trim() !== '' &&
                notificationMessage &&
                notificationMessage.trim() !== '' &&
                endDate
            );
        }
    };

    function onChangeDateRange(startDate, endDate) {
        let fromDate = startDate === null || startDate === undefined ? null : format(new Date(startDate), 'yyyy/MM/dd');
        let toDate = endDate === null || startDate === undefined ? null : format(new Date(endDate), 'yyyy/MM/dd');
        if (fromDate !== timeRangeRef.current?.runFrom || toDate !== timeRangeRef.current?.runTo) {
            timeRangeRef.current = { runFrom: fromDate, runTo: toDate };
            setDefaultStartDate(startDate === null || startDate === undefined ? null : new Date(startDate));
            setDefaultEndDate(endDate === null || startDate === undefined ? null : new Date(endDate));
        }
    }

    const buttonLabel = useState(edit ? 'Edit' : 'Submit');
    return (
        <>
            {!edit && (
                <>
                    {' '}
                    <h1>Market Based Notification</h1>
                    <hr />
                </>
            )}

            {edit && (
                <>
                    {' '}
                    <h1>Edit Market Based Notification</h1>
                    <hr />
                    <br />
                </>
            )}
            {marketsApiState === API_STATES.loading && (
                <p className={generalStyles.statusMessage}>Loading Markets List...</p>
            )}
            {marketsApiState === API_STATES.error && (
                <p className={generalStyles.errorMessage}>{marketsApiError.toString()}</p>
            )}
            {markets?.length > 0 && (
                <>
                    <div className={styles.selectionContainer}>
                        {/* Market select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Markets:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'reactSelect'}
                                    options={Constant.FIRST_OPTION.concat(markets)}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    onChange={(selectedMarket) => selectMarkets(selectedMarket)}
                                    value={selectedMarkets}
                                    placeholder="Markets"
                                    styles={Constant.SELECT_STYLES}
                                    isDisabled={editSingleNotification}
                                />
                            </span>
                        </div>

                        {/* Notification Type select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Notification Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.NOTIFICATION_TYPE}
                                    closeMenuOnSelect={false}
                                    onChange={(selectedValue) => setMarketNotificationType(selectedValue)}
                                    value={marketNotificationType}
                                    placeholder="Notification Type"
                                    styles={Constant.SELECT_STYLES}
                                    isDisabled={editSingleNotification}
                                />
                            </span>
                        </div>

                        {/* Run Type select */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formFieldLabel}>Select Type:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    //TODO : to be changed later after market subscription based change
                                    options={
                                        marketNotificationType.value === 'marketDefinitionChange'
                                            ? [Constant.RUN_TYPE[1]]
                                            : Constant.RUN_TYPE
                                    }
                                    // options={[Constant.RUN_TYPE[1]]}
                                    closeMenuOnSelect={false}
                                    onChange={(selectedType) => setTriggerType(selectedType)}
                                    value={triggerType}
                                    placeholder="Type"
                                    styles={Constant.SELECT_STYLES}
                                    isDisabled={editSingleNotification}
                                />
                            </span>
                        </div>
                    </div>

                    <br />

                    <div className={styles.selectionContainer}>
                        {/* date range selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Time Range:</label>

                            <div className={styles.datepickerContainer}>
                                {(triggerType?.value === 'reportBased' || triggerType[0]?.value === 'reportBased') 
                                    && (!editSingleNotification) && (
                                    <ByzzerDateRange
                                        ref={dateRangeRef}
                                        onChange={onChangeDateRange}
                                        dateSelected={false}
                                        placeholder="Time Range"
                                        clearDateRange={clearDateRange}
                                        dateFormat={'MMM d, y'}
                                        minDate={new Date(2021, 0, 1)}
                                        maxDate={new Date()}
                                        disabled={
                                            triggerType?.value === 'reportBased' ||
                                            triggerType[0]?.value === 'reportBased'
                                                ? false
                                                : true
                                        }
                                        defaultStartDate={defaultStartDate}
                                        defaultEndDate={defaultEndDate}
                                    />
                                )}

                                {((triggerType?.value !== 'reportBased' && triggerType[0]?.value !== 'reportBased') 
                                    || editSingleNotification) && (
                                    <div className={styles.disabledDatePicker}>
                                        <Select
                                            classNamePrefix={'react-select'}
                                            closeMenuOnSelect={false}
                                            placeholder="Time Range"
                                            styles={Constant.DATE_SELECT_STYLES}
                                            isDisabled={true}
                                        />
                                    </div>
                                )}
                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>

                        {/* Message expiration date selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Expiration Date:</label>

                            <div className={styles.datepickerContainer}>
                                <DatePicker
                                    name="endDate"
                                    placeholderText="End Date"
                                    shouldCloseOnSelect={true}
                                    className={styles.datepickerInput}
                                    fixedHeight
                                    closeOnScroll={true}
                                    dateFormat="MMM d, y"
                                    selected={endDate ? endDate.toDate() : null}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    tabIndex={1}
                                    onChange={(date) => {
                                        onDateChange(date ? moment(date) : null);
                                    }}
                                    minDate={moment().toDate()}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                                <img src={calendarIcon} alt="calendar icon" />
                            </div>
                        </div>

                        {/* Notification type selection */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Select Type of Notification:</label>
                            <span className={styles.formField}>
                                <Select
                                    classNamePrefix={'react-select'}
                                    options={Constant.TYPE_OF_NOTIFICATION}
                                    onChange={(selectedValue) => setSelectedNotificationType(selectedValue)}
                                    value={selectedNotificationType}
                                    placeholder={'Type of Notification'}
                                />
                            </span>
                        </div>
                    </div>
                    <br />

                    <div className={styles.selectionContainer}>
                        {/* input for message header/title */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Notification Header:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationHeader(e.target.value);
                                    }}
                                    value={notificationHeader}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Header'}
                                />
                            </span>
                        </div>

                        {/* input for message text */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Message:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationMessage(e.target.value);
                                    }}
                                    value={notificationMessage}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Notification Message'}
                                />
                            </span>
                        </div>
                        {/* input for adding URL for performing any action on the message */}
                        <div className={styles.selectInputWrapper}>
                            <label className={styles.formLabel}>Action:</label>
                            <span className={styles.formField}>
                                <textarea
                                    onChange={(e) => {
                                        setNotificationLink(e.target.value);
                                    }}
                                    value={notificationLink}
                                    className={generalStyles.textareaInput}
                                    placeholder={'Link (Optional) - Link for redirection'}
                                />
                            </span>
                        </div>

                        {/* button to submit all form selections to generate the message and send notification to selected users */}
                        <div>
                            <button
                                className={`${generalStyles.submitButton}`}
                                name={'submit'}
                                onClick={displayPreview}
                                disabled={validateSelection()}
                            >
                                Submit
                            </button>
                            {!edit &&
                                <button
                                className={`${generalStyles.submitButton}`}
                                name={'submit'}
                                onClick={onScheduleNotification}
                                disabled={validateSelection()}
                                >
                                    Schedule
                                </button>
                            }
                            <div>
                                {createApiState === API_STATES.loading && (
                                    <span className={generalStyles.statusMessage}>
                                        Triggering Market Based Notification...
                                    </span>
                                )}
                                {createApiState === API_STATES.error && (
                                    <span className={generalStyles.errorMessage}>Failed: {createApiError}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <Popup
                        open={openModal}
                        onClose={() => {
                            setOpenModal(false);
                        }}
                        modal
                    >
                        {() => (
                            <div className={styles.warningDialog}>
                                <h2>Preview</h2>
                                <div className={styles.messageContainer}>
                                    <div>
                                        {' '}
                                        <b>{notificationHeader}</b>: {notificationMessage}
                                    </div>
                                    <br></br>

                                    {notificationLink !== undefined &&
                                        notificationLink !== null &&
                                        notificationLink.trim() !== '' && (
                                            <>
                                                <div>
                                                    <b>Link</b>: {notificationLink}
                                                </div>
                                                <br></br>
                                            </>
                                        )}

                                    {!edit && (
                                        <div>
                                            <b>Note</b>: This message will be sent to {userCount} accounts
                                        </div>
                                    )}    
                                    {edit && !editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for all notified companies.
                                        </div>
                                    )}
                                    {edit && editSingleNotification && (
                                        <div>
                                            <b>Note</b>: This Message will be modified for selected company.
                                        </div>
                                    )}
                                </div>

                                <div>
                                    <button
                                        className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            sendNotification();
                                        }}
                                    >
                                        {buttonLabel}
                                    </button>

                                    <button
                                        className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            setOpenModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </Popup>
                    <Popup
                        open={openScheduleNotificationModal}
                        onClose={() => {
                            setOpenScheduleNotificationModal(false);
                        }}
                        modal
                    >
                        {() => (
                            <div className={styles.warningDialog}>
                                <h2>Preview</h2>
                                <div className={styles.messageContainer}>
                                    <div>
                                        {' '}
                                        <b>{notificationHeader}</b>: {notificationMessage}
                                    </div>
                                    <br></br>

                                    {notificationLink !== undefined &&
                                        notificationLink !== null &&
                                        notificationLink.trim() !== '' && (
                                            <>
                                                <div>
                                                    <b>Link</b>: {notificationLink}
                                                </div>
                                                <br></br>
                                            </>
                                        )}

                                    {!edit && (
                                        <div>
                                            <b>Note</b>: This message will be sent to {userCount} accounts
                                        </div>
                                    )}    
                                    <br/>
                                    <div className={styles.etaForm}>
                                        <b>Select Date & Time: </b>
                                        <div className={styles.date}>
                                            <DatePicker
                                                data-test="schedule-notification-datePicker"
                                                name="scheduleDate"
                                                placeholderText="dd/MM/yyyy"
                                                shouldCloseOnSelect={true}
                                                fixedHeight
                                                closeOnScroll={true}
                                                minDate={new Date()}
                                                dateFormat="MMM d, y"
                                                selected={time.toDate()}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                tabIndex={1}
                                                onChange={(newDate) => {
                                                    updateScheduleDate(time, newDate);
                                                }}
                                            />
                                            <img src={calendarIcon} alt="calendar icon" />
                                        </div>
                                        <div className={styles.time}>
                                            <div className={styles.timePicker} ref={timePickerRef}>
                                                <div className={styles.timeDisplay} onClick={() => setShowTimePicker(!showTimePicker)}>
                                                    <input value={time.format('hh:mm a')} />
                                                    <img src={clockIcon} alt="clock icon" />
                                                </div>
                                                {showTimePicker && (
                                                    <div className={styles.timePickerElementWrapper}>
                                                        <TimeKeeper
                                                            data-test="maintenance-timePicker"
                                                            time={time.format('hh:mm a')}
                                                            onChange={(newTime) => updateScheduleTime(time, newTime)}
                                                            onDoneClick={() => setShowTimePicker(false)}
                                                            switchToMinuteOnHourSelect
                                                            closeOnMinuteSelect
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        className={`${generalStyles.cancelOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            onSubmitSchedulednotification(time);
                                        }}
                                        disabled={!time || scheduledDate?.isSame(time) || time < moment() || updateScheduledDateApiState !== API_STATES.none}
                                    >
                                       Schedule
                                    </button>

                                    <button
                                        className={`${generalStyles.confirmOperationButton} ${styles.btnWidth}`}
                                        onClick={() => {
                                            setOpenScheduleNotificationModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </Popup>
                </>
            )}
        </>
    );
}

export default MarketBasedNotificationForm;
