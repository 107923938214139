import generalStyles from '@/components/General/Styles.module.scss';
import { MultiSelectColumnFilter } from '../General/MultiSelectColumnFilter';
import { FILTER_TYPES } from '../General/DataTable';
import { DateRangeColumnFilter } from '../General/DateRangeColumnFilter';
import { Link } from 'react-router-dom';


export const COLUMNS = [
    {
        Header: 'Company Id',
        accessor: 'companyId',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        Cell: ({ value }) => (
            <Link className={generalStyles.link} to={`/newcompanypage/${value}`}>
                {value}
            </Link>
        ),
        disableFilters: true,
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
        id: 'companyName',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Is Disabled',
        accessor: 'isDisabled',
        id: 'isDisabled',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Sales Rep First Name',
        accessor: 'salesRepFname',
        id: 'salesRepFname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Sales Rep Last Name',
        accessor: 'salesRepLname',
        id: 'salesRepLname',
        className: `${generalStyles.tableCell} ${generalStyles.cap}`,
        disableFilters: true,
    },
    {
        Header: 'Sales Rep Email',
        accessor: 'salesRepEmail',
        id: 'salesRepEmail',
        className: `${generalStyles.tableCell}`,
        disableFilters: true,
    },
    {
        Header: 'Report Type',
        accessor: 'reportType',
        id: 'reportType',
        className: generalStyles.tableCell,
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: 'Subscription',
        accessor: 'subscriptionName',
        id: 'subscriptionName',
        className: generalStyles.tableCell,
        disableSortBy: true,
        Filter: MultiSelectColumnFilter,
        filter: FILTER_TYPES.multiple,
    },
    {
        Header: 'Has Active Subscription',
        accessor: 'hasActiveSubscription',
        className: generalStyles.tableCell,
    },
    {
        Header: 'Access End Date',
        accessor: 'accessEndDate',
        className: generalStyles.tableCell,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: "Multi Year Agreement",
        accessor: "hasMultiYearAgreement",
        className: generalStyles.tableCell,
    },
    {
        Header: 'Items Purchased',
        accessor:'itemsPurchased',
        id:'itemsPurchased',
        className: generalStyles.tableCell,
        Filter: MultiSelectColumnFilter,
        Cell: ({ value }) =>  Array.isArray(value) ? value?.join(', ') : value ?? '',
        filter: FILTER_TYPES.multiple
    },
    {
        Header: 'Total Alerts Configured',
        accessor: 'alertsCount',
        id: 'alertsCount',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
    {
        Header: 'Purchased Categories',
        accessor: 'baseCategories',
        id: 'baseCategories',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
    {
        Header: 'Selected Categories',
        accessor: 'usedCategories',
        id: 'usedCategories',
        className: generalStyles.tableCell,
        disableFilters: true,
    },
    {
        Header: 'Contract Start',
        accessor: 'contractStartDate',
        id: 'contractStartDate',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'Contract End',
        accessor: 'contractEndDate',
        id: 'contractEndDate',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        Filter: DateRangeColumnFilter,
        filter: FILTER_TYPES.dateRange,
    },
    {
        Header: 'Percent Through Contract',
        accessor: 'percentContractCompleted',
        id: 'percentContractCompleted',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Runs',
        accessor: 'baseRuns',
        id: 'baseRuns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Add On',
        accessor: 'baseAddOns',
        id: 'baseAddOns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Credits',
        accessor: 'baseCredits',
        id: 'baseCredits',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Used',
        accessor: 'baseUsed',
        id: 'baseUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Unused',
        accessor: 'baseUnused',
        id: 'baseUnused',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Base Percent Used',
        accessor: 'basePercentUsed',
        id: 'basePercentUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Runs',
        accessor: 'smartRuns',
        id: 'smartRuns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Add On',
        accessor: 'smartAddOns',
        id: 'smartAddOns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Credits',
        accessor: 'smartCredits',
        id: 'smartCredits',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Used',
        accessor: 'smartUsed',
        id: 'smartUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Unused',
        accessor: 'smartUnused',
        id: 'smartUnused',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Smart Percent Used',
        accessor: 'smartPercentUsed',
        id: 'smartPercentUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Runs',
        accessor: 'totalRuns',
        id: 'totalRuns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Add On',
        accessor: 'totalAddOns',
        id: 'totalAddOns',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Credits',
        accessor: 'totalCredits',
        id: 'totalCredits',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Used',
        accessor: 'totalUsed',
        id: 'totalUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Unused',
        accessor: 'totalUnused',
        id: 'totalUnused',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    },
    {
        Header: 'Total Percent Used',
        accessor: 'totalPercentUsed',
        id: 'totalPercentUsed',
        className: `${generalStyles.tableCell} ${generalStyles.numeric}`,
        disableFilters: true
    }
];
