import React, { useEffect, useState } from 'react';
import { deleteByzzerUserFirebaseAuth, getFirebaseUser, getUserPasswordResetStatus, updateUserPasswordResetStatus } from '@/services/api/userdata.service';
import ObjectConfigTable from '@/components/General/ObjectConfigTable';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError, noOp } from '@/components/General/HandleApiError';
import styles from '@/components/UserConfig/UserConfig.module.scss';
import EmailVerification from '@/components/UserConfig/EmailVerification';
import UserActions from '@/components/NewCompanyPage/UserActions';
import { setDisableAuthValue } from '@/services/api/csrdata.service';
import isEmail from 'is-email';
import { UserDetails } from './UserDetails';
import ByzzerSwitch from '../General/ByzzerSwitch';
import { getCompanyUserConfig, getInvitationLink, resendInvitationEmail } from '@/services/api/company.service';
import UserInvitationApproval from '../UserConfig/UserInvitationApproval';

function UserAccordionItem({ nsUserId, nsCompanyId, email, isEditable = true, isConsultant = false, isPendingInvitation = false, invitationApprovalStatus = null }) {
    // state
    const [fetchFirebaseUserApiStatus, setFetchFirebaseUserApiStatus] = useState(API_STATES.none);
    const [fetchFirebaseUserApiError, setFetchFirebaseUserApiError] = useState('');
    const [firebaseUser, setFirebaseUser] = useState(null);

    const [fetchResetPasswordApiStatus, setFetchResetPasswordApiStatus] = useState(API_STATES.none);
    const [fetchResetPasswordApiError, setFetchResetPasswordApiError] = useState('');
    const [isForcePasswordReset, setIsForcePasswordReset] = useState(false);

    const [fetchUserConfigApiStatus, setFetchUserConfigApiStatus] = useState(API_STATES.none);
    const [fetchUserConfigApiError, setFetchUserConfigApiError] = useState('');
    const [userConfig, setUserConfig] = useState({});

    const [sendInviteApiStatus, setSendInviteApiStatus] = useState(API_STATES.none);
    const [sendInviteApiError, setSendInviteApiError] = useState('');

    const [fetchInvitationLinkApiStatus, setFetchInvitationLinkApiStatus] = useState(API_STATES.none)
    const [fetchInvitationLinkApiError, setFetchInvitationLinkApiError] = useState('');

    const [updateResetPasswordApiStatus, setUpdateResetPasswordApiStatus] = useState(API_STATES.none);
    const [updateResetPasswordApiError, setUpdateResetPasswordApiError] = useState('');

    const [updateDisableAuthApiStatus, setUpdateDisableAuthApiStatus] = useState(API_STATES.none);
    const [updateDisableAuthApiError, setUpdateDisableAuthApiError] = useState('');

    const [deleteFirebaseUserApiStatus, setDeleteFirebaseUserApiStatus] = useState(API_STATES.none);
    const [deleteFirebaseUserApiError, setDeleteFirebaseUserApiError] = useState('');

    async function loadCompanyUserConfig(nsCompanyId, nsUserId) {
        setFetchUserConfigApiStatus(API_STATES.loading)
        const response = await getCompanyUserConfig(nsCompanyId, nsUserId);
        if (response.status === API_STATES.success) {
            setUserConfig(response.data);
            setFetchUserConfigApiStatus(API_STATES.success)
        } else {
            let error = response.error;
            handleApiError(error, setFetchUserConfigApiStatus, setFetchUserConfigApiError);
        }
    }

    async function fetchInvitationLink() {
        setFetchInvitationLinkApiStatus(API_STATES.loading);
        const result = await getInvitationLink(email.toLowerCase(), nsCompanyId);
        if (result.status === API_STATES.success) {
            navigator.clipboard.writeText(result.data);
            setFetchInvitationLinkApiStatus(API_STATES.success);
        } else {
            let error = result.error;
            handleApiError(
                error,
                setFetchInvitationLinkApiStatus,
                setFetchInvitationLinkApiError,
                `Invitation Record for ${email} not found`
            );
        }
        setTimeout(() => setFetchInvitationLinkApiStatus(API_STATES.none), 3000);
    }

    //get resetPassword value
    async function fetchUserPasswordResetStatus(nsUserId) {
        if (!nsUserId) return;
        setFetchResetPasswordApiStatus(API_STATES.loading);
        const result = await getUserPasswordResetStatus(nsUserId);
        if (result.status === API_STATES.success) {
            setFetchResetPasswordApiStatus(API_STATES.success);
            setIsForcePasswordReset(result.data.resetValue);
        } else {
            let error = result.error;
            handleApiError(error, setFetchResetPasswordApiStatus, setFetchResetPasswordApiError, 'User not found');
        }
    }

    async function updateForceResetPasswordStatus(e) {
        setIsForcePasswordReset(e.target.checked)
        setUpdateResetPasswordApiStatus(API_STATES.loading);
        const result = await updateUserPasswordResetStatus(nsUserId, e.target.checked);
        if (result.status === API_STATES.success) {
            setUpdateResetPasswordApiStatus(API_STATES.success);
            fetchUserPasswordResetStatus(nsUserId);
        } else {
            let error = result.error;
            handleApiError(error, setUpdateResetPasswordApiStatus, setUpdateResetPasswordApiError);
        }
    }

    async function resendInvitationMail() {
        setSendInviteApiStatus(API_STATES.loading);
        if (email && !isEmail(email)) {
            setSendInviteApiStatus(API_STATES.error);
            setSendInviteApiError('Invalid Email Format!');
        } else {
            const result = await resendInvitationEmail(email.toLowerCase(), nsCompanyId);
            if (result.status === API_STATES.success) {
                setSendInviteApiStatus(API_STATES.success);
                setTimeout(() => setSendInviteApiStatus(API_STATES.none), 3000);
            } else {
                let error = result.error;
                handleApiError(
                    error,
                    setSendInviteApiStatus,
                    setSendInviteApiError,
                    `Invitation Record for email ${email} not found`
                );
                setTimeout(() => setSendInviteApiStatus(API_STATES.none), 3000);
            }
        }
    }

    async function loadFirebaseUser(email) {
        setFetchFirebaseUserApiStatus(API_STATES.loading);
        const result = await getFirebaseUser(email);
        if (result.status === API_STATES.success) {
            setFirebaseUser(result.data)
            setFetchFirebaseUserApiStatus(API_STATES.success);
        } else {
            let error = result.error;
            setFirebaseUser(null);
            handleApiError(error, setFetchFirebaseUserApiStatus, setFetchFirebaseUserApiError, `Customer with email address ${email} does not exist in the authentication database.`)
        }
    }

    async function handleDeleteFirebaseUser(email) {
        setDeleteFirebaseUserApiStatus(API_STATES.loading)
        const response = await deleteByzzerUserFirebaseAuth(email)
        if (response.status === API_STATES.success) {
            setDeleteFirebaseUserApiStatus(API_STATES.success)
            loadFirebaseUser(email)
        } else {
            handleApiError(
                response.error,
                setDeleteFirebaseUserApiStatus,
                setDeleteFirebaseUserApiError
            )
        }
        setTimeout(() => setDeleteFirebaseUserApiStatus(API_STATES.none), 6000)
    }

    useEffect(() => {
        loadFirebaseUser(email);
    }, [email]);

    useEffect(() => {
        fetchUserPasswordResetStatus(nsUserId);
    }, [nsUserId]);

    useEffect(() => {
        loadCompanyUserConfig(nsCompanyId, nsUserId);
    }, [nsCompanyId, nsUserId])

    //set disable auth value
    async function updateDisabledAuthStatus(email, isDisabled) {
        setUpdateDisableAuthApiStatus(API_STATES.loading);
        const result = await setDisableAuthValue(email, isDisabled);
        if (result.status === API_STATES.success) {
            setUpdateDisableAuthApiStatus(API_STATES.success);
            loadFirebaseUser(email);
            setTimeout(() => setUpdateDisableAuthApiStatus(API_STATES.none), 4000);
        } else {
            let error = result.error;
            handleApiError(
                error,
                setUpdateDisableAuthApiStatus,
                setUpdateDisableAuthApiError,
                'Unable to set Disable Authentication - data not found',
                'Unknown error in setting Disable Authentication status'
            )
            setUpdateDisableAuthApiStatus(API_STATES.error);
        }
    }

    ///////////////////////////////////////////////////////////
    return (
        <div>
            {invitationApprovalStatus !== null && <UserInvitationApproval email={email} nsCompanyId={nsCompanyId} invitationApprovalStatus={invitationApprovalStatus} />}
            {fetchFirebaseUserApiStatus === API_STATES.loading ? (
                <label className={generalStyles.statusMessage}>Fetching Firebase user...</label>
            ) : fetchFirebaseUserApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>
                    Failed to fetch Firebase user: {fetchFirebaseUserApiError}
                </p>
            ) : firebaseUser && nsUserId && (
                <>
                    <EmailVerification email={email} isEditable={isEditable && !isConsultant} />
                    <br />
                    <div data-test="disable-auth-component">
                        <div className={generalStyles['input-wrapper']}>
                            <label>Disable Authentication: </label>
                            <input
                                data-test="disable-auth-checkbox"
                                type="checkbox"
                                checked={firebaseUser.disabled}
                                onChange={(e) => updateDisabledAuthStatus(email, e.target.checked)}
                                disabled={
                                    !isEditable ||
                                    isConsultant ||
                                    updateDisableAuthApiStatus === API_STATES.loading ||
                                    fetchFirebaseUserApiStatus !== API_STATES.success
                                }
                            />
                            <label data-test="disable-auth-checkbox-result">
                                {updateDisableAuthApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Processing...</span>
                                ) : updateDisableAuthApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>
                                        Failed to Update: {updateDisableAuthApiError}
                                    </span>
                                ) : (
                                    updateDisableAuthApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}>saved!</span>
                                    )
                                )}
                            </label>
                        </div>
                    </div>
                </>
            )}
            {nsUserId ? (<>
                <div data-test="reset-password-component">
                    {fetchResetPasswordApiStatus === API_STATES.loading ? (
                        <label className={generalStyles.statusMessage}>Fetching password reset status</label>
                    ) : fetchResetPasswordApiStatus === API_STATES.error ? (
                        <label className={generalStyles.errorMessage}>
                            Failed to fetch password reset status: {fetchResetPasswordApiError}
                        </label>
                    ) : (
                        <>
                            <div className={generalStyles['input-wrapper']}>
                                <label>Force Password Reset: </label>
                                <ByzzerSwitch
                                    disabled={
                                        !isEditable ||
                                        isConsultant ||
                                        fetchResetPasswordApiStatus === API_STATES.loading ||
                                        fetchResetPasswordApiStatus !== API_STATES.success
                                    }
                                    value={isForcePasswordReset}
                                    name="forcePasswordReset"
                                    onChange={(e) => updateForceResetPasswordStatus(e)}
                                ></ByzzerSwitch>
                            </div>
                            <label data-test="disable-password-reset-check">
                                {updateResetPasswordApiStatus === API_STATES.loading ? (
                                    <span className={generalStyles.statusMessage}>Processing...</span>
                                ) : updateResetPasswordApiStatus === API_STATES.error ? (
                                    <span className={generalStyles.errorMessage}>
                                        Failed to Update: {updateResetPasswordApiError}
                                    </span>
                                ) : (
                                    updateResetPasswordApiStatus === API_STATES.success && (
                                        <span className={generalStyles.successMessage}>saved!</span>
                                    )
                                )}
                            </label>
                        </>
                    )}
                </div>
                <UserDetails nsUserId={nsUserId} nsCompanyId={nsCompanyId} isEditable={isEditable} />
                <br />
                <b>User Configuration</b>
                {fetchUserConfigApiStatus === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}> Company User Config Error: {fetchUserConfigApiError} </span>
                ) : fetchUserConfigApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading...</span>
                ) : fetchUserConfigApiStatus === API_STATES.success && (
                    <ObjectConfigTable configBlob={userConfig} />
                )}
                <br />
                <UserActions email={email} nsCompanyId={nsCompanyId} isConsultant={isConsultant} />
            </>) : (<>

                {isPendingInvitation && <>
                    <p className={generalStyles.statusMessage}>Note: User Has not accepted Invitation yet.</p>
                    {firebaseUser && <>
                        <p className={generalStyles.errorMessage}>
                            Found an existing firebase authentication record with the invited user email.
                        </p>
                        <p className={generalStyles.statusMessage}>
                            This will cause the invitation acceptance flow to fail.
                            Please consider deleting the firebase user.
                        </p>
                        <button
                            className={generalStyles.confirmOperationButton}
                            onClick={() => handleDeleteFirebaseUser(email)}
                            disabled={deleteFirebaseUserApiStatus !== API_STATES.none}
                        >
                            Delete Firebase User
                        </button>
                    </>}
                </>}
                <button className={generalStyles.submitButton} onClick={resendInvitationMail} disabled={sendInviteApiStatus !== API_STATES.none}>
                    ReSend Invite
                </button>
                <button className={generalStyles.submitButton} onClick={fetchInvitationLink} disabled={fetchInvitationLinkApiStatus !== API_STATES.none}>
                    Copy Invite Link
                </button>
                {deleteFirebaseUserApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Deleting firebase user...</span>
                ) : deleteFirebaseUserApiStatus === API_STATES.error ? (
                    <span className={generalStyles.errorMessage}>
                        Failed to delete: {deleteFirebaseUserApiError}
                    </span>
                ) : deleteFirebaseUserApiStatus === API_STATES.success && (
                    <span className={generalStyles.successMessage}>Deleted!</span>
                )}
                {sendInviteApiStatus === API_STATES.error ? (
                    <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {sendInviteApiError}
                    </span>
                ) : sendInviteApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Loading...</span>
                ) : sendInviteApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : sendInviteApiStatus === API_STATES.success && (
                    <span className={generalStyles.successMessage}>Successfully sent Invitation email.</span>
                )}
                {fetchInvitationLinkApiStatus === API_STATES.error ? (
                    <span className={`${generalStyles.errorMessage} ${styles.errorMessage}`}>
                        Error: {fetchInvitationLinkApiError}
                    </span>
                ) : fetchInvitationLinkApiStatus === API_STATES.loading ? (
                    <span className={generalStyles.statusMessage}>Fetching...</span>
                ) : fetchInvitationLinkApiStatus === API_STATES.none ? (
                    <span className={generalStyles.statusMessage}></span>
                ) : fetchInvitationLinkApiStatus === API_STATES.success && (
                    <span className={generalStyles.statusMessage} data-test="copy-link-status">
                        Copied!
                    </span>
                )}
            </>)}
        </div>
    );
}

export default UserAccordionItem;
