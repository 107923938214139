import React, { useEffect, useState, useContext } from 'react';
import { getAllTeamMembersByCompanyId, removeUserFromCompany, getUserRemovalImpactCount } from '@/services/api/company.service';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import { handleApiError } from '@/components/General/HandleApiError';
import { companyPageContext } from '@/components/NewCompanyPage/NewCompanyPage';
import { Collapse } from 'antd';
import './Accordion.scss';
import UserAccordionItem from '@/components/NewCompanyPage/UserAccordionItem';
import store from '../../store';
import deleteIcon from '@images/icons/deleteIcon.svg';
import InlineSVG from 'react-inlinesvg';
import Popup from 'reactjs-popup';
const { Panel } = Collapse;

function CompanyUsersList({ nsCompanyId }) {
    const [openPanels, setOpenPanels] = useState([]);
    const { isCsr, isSuperUser, isSales  } = store?.getState()?.account ?? {};

    const canDeleteUser = isSuperUser || isCsr || isSales

    const { nsUserId } = useContext(companyPageContext);

    const [userList, setUserList] = useState([]);
    const [userListApiStatus, setUserListApiStatus] = useState(API_STATES.none);
    const [userListApiError, setUserListApiError] = useState('');

    const [deletingUser, setDeletingUser] = useState(null);
    const [userImpactCount, setUserImpactCount] = useState(null)
    const [userRemovalImpactCountApiState, setUserRemovalImpactCountApiState] = useState(API_STATES.none)
    const [userRemovalImpactCountApiError, setUserRemovalImpactCountApiError] = useState('');
    const [removeUserFromCompanyApiState, setRemoveUserFromCompanyApiState] = useState(API_STATES.none);
    const [removeUserFromCompanyApiError, setRemoveUserFromCompanyApiError] = useState('');

    async function getUserList(nsCompanyId) {
        setUserListApiStatus(API_STATES.loading);
        setUserList([]);
        // { nsUserId, firstName, lastName, email, isConsultant }
        const result = await getAllTeamMembersByCompanyId(nsCompanyId);
        switch (result.status) {
            case API_STATES.success:
                if (result.data.length === 0) {
                    setUserListApiStatus(API_STATES.error);
                    setUserListApiError('No user found in this company');
                } else {
                    setUserListApiStatus(API_STATES.success);
                    setUserList(result.data);
                }
                break;
            case API_STATES.error:
                const error = result.error;
                handleApiError(error, setUserListApiStatus, setUserListApiError, 'Company User(s) not found');
                break;
            default:
                setUserListApiError('');
                setUserListApiStatus(API_STATES.none);
        }
    }

    async function loadUserRemovalImpactCount(byzUserId, nsCompanyId) {
        setUserRemovalImpactCountApiState(API_STATES.loading)
        const result = await getUserRemovalImpactCount(byzUserId, nsCompanyId)
        if (result) {
            if (result.status === API_STATES.success) {
                setUserRemovalImpactCountApiState(API_STATES.success)
                setUserImpactCount({
                    alerts: result.data.sharedAlertsCount,
                    scheduledExtractsCount: result.data.scheduledExtractsCount
                })
            } else {
                handleApiError(result.error, setUserRemovalImpactCountApiState, setUserRemovalImpactCountApiError)
                setTimeout(() => setUserRemovalImpactCountApiState(API_STATES.none), 5000)
            }
        }
    }

    const onDeleteUserClick = (email, byzUserId) => {
        setUserImpactCount(null)
        if (byzUserId) {
            loadUserRemovalImpactCount(byzUserId, nsCompanyId)
        }
        setDeletingUser({ byzUserId, email })
    }

    const onCancelDeleteUser = () => {
        setDeletingUser(null)
    }

    const onConfirmDeleteUser = async (nsCompanyId, email) => {
        setRemoveUserFromCompanyApiState(API_STATES.loading);
        const response = await removeUserFromCompany(nsCompanyId, email);
        if (response.status === API_STATES.success) {
            setRemoveUserFromCompanyApiState(API_STATES.success);
            setTimeout(() => {
                setDeletingUser(null)
                getUserList(nsCompanyId)
                setRemoveUserFromCompanyApiState(API_STATES.none)
            }, 3000)
        } else {
            handleApiError(
                response.error,
                setRemoveUserFromCompanyApiState,
                setRemoveUserFromCompanyApiError
            )
            setTimeout(() => setRemoveUserFromCompanyApiState(API_STATES.none), 6000)
        }
    }

    useEffect(() => {
        (async () => {
            if (nsCompanyId !== null && nsCompanyId !== 0) {
                getUserList(nsCompanyId);
            }
        })();
    }, [nsCompanyId]);

    useEffect(() => {
        if (nsUserId !== 0 || nsUserId !== null) {
            setOpenPanels([String(nsUserId)]);
        }
    }, [nsUserId]);

    return (
        <>
            {userListApiStatus === API_STATES.error ? (
                <p className={generalStyles.errorMessage}>{userListApiError}</p>
            ) : userListApiStatus === API_STATES.none ? (
                ''
            ) : userListApiStatus === API_STATES.loading ? (
                <p className={generalStyles.statusMessage}>Loading company users...</p>
            ) : userListApiStatus === API_STATES.success && (
                <Collapse
                    activeKey={openPanels}
                    onChange={setOpenPanels}
                    className={'alerts-list-accordion'}
                    expandIconPosition={'end'}
                >
                    {userList.map((dto) => {
                        return (
                            <Panel
                                key={dto.nsUserId}
                                header={
                                    <div className={'alerts-list-panel-heading'}>
                                        <span className={'alerts-list-panel-heading-title'}>
                                            {
                                                <b>
                                                    {dto.firstName} {dto.lastName} - {dto.email} {dto.isConsultant && '(consultant)'}
                                                </b>
                                            }
                                        </span>

                                        {canDeleteUser &&
                                            <InlineSVG
                                                className={'alerts-list-panel-heading-delete-icon'}
                                                src={deleteIcon}
                                                onClick={(event) => {
                                                    onDeleteUserClick(dto.email, dto.byzUserId)
                                                    event.stopPropagation()
                                                }}
                                            />
                                        }
                                    </div>
                                }
                            >
                                <UserAccordionItem nsUserId={dto.nsUserId} email={dto.email} nsCompanyId={nsCompanyId} isEditable={isCsr || isSuperUser} isConsultant={dto.isConsultant} invitationApprovalStatus={dto.invitationApprovalStatus} isPendingInvitation={dto.isPendingInvitation}/>
                            </Panel>
                        );
                    })}
                </Collapse>
            )}
            {Boolean(deletingUser) &&
                <Popup
                    open={canDeleteUser && Boolean(deletingUser)}
                    onClose={onCancelDeleteUser}
                    modal
                >
                    {() => (
                        <>
                            {userRemovalImpactCountApiState === API_STATES.loading ? (
                                <><br /><p className={`${generalStyles.statusMessage}`}> Getting user impact count...</p> <br /></>
                            ) : userRemovalImpactCountApiState === API_STATES.error ? (
                                <p>{userRemovalImpactCountApiError}</p>
                            ) : (
                                <div>
                                    <b>Are you sure you want to delete "{deletingUser.email}" member?</b>
                                    <br /><br />
                                    {userImpactCount &&
                                        <>
                                            <p>By proceeding, you will not receive any future deliveries associated with this member:</p>
                                            <br />
                                            <ul className={`${generalStyles.listItemContainer}`}>
                                                <li>{userImpactCount.alerts} Alerts that other members receive</li>
                                                <li>{userImpactCount.scheduledExtractsCount} Scheduled Data On Demand Series</li>
                                            </ul>
                                        </>
                                    }
                                    <br />
                                    This action cannot be undone. Please review carefully before confirming deletion.
                                    <br /><br />
                                    <div className={generalStyles.row}>
                                        <button
                                            className={generalStyles.confirmOperationButton}
                                            onClick={() => onConfirmDeleteUser(nsCompanyId, deletingUser.email)}
                                            disabled={
                                                !canDeleteUser ||
                                                removeUserFromCompanyApiState === API_STATES.loading ||
                                                removeUserFromCompanyApiState === API_STATES.success
                                            }
                                        >
                                            Delete User From Company
                                        </button>
                                        {removeUserFromCompanyApiState === API_STATES.loading ? (
                                            <span className={generalStyles.statusMessage}>
                                            Removing user from company...
                                            </span>
                                        ) : removeUserFromCompanyApiState === API_STATES.error ? (
                                                <span className={generalStyles.errorMessage}>
                                                    {removeUserFromCompanyApiError}
                                                </span>
                                        ) : removeUserFromCompanyApiState === API_STATES.success ? (
                                                <span className={generalStyles.successMessage}>
                                                    Successfully removed user
                                                </span>
                                        ) : (
                                            <button
                                            className={generalStyles.submitButton}
                                            onClick={onCancelDeleteUser}
                                        >
                                            Cancel
                                        </button>
                                        )}
                                    </div>
                                </div>
                            )
                                    
                            }
                        </>
                    )}
                </Popup>
            }
        </>
    );
}

function UsersTab() {
    let { nsCompanyId, companyConfig: { companyName, companyAccessLevel, isMultiCompany } } = useContext(companyPageContext);

    return (
        <>
            <div>
                <h2 style={{ color: 'black' }}>
                    {companyName}
                    {isMultiCompany && <span className={generalStyles.statusMessage}>(multi company)</span>}
                </h2>

                <strong>{companyAccessLevel}</strong>
            </div>
            <br />
            <CompanyUsersList nsCompanyId={nsCompanyId} />
        </>
    );
}

export default UsersTab;
