import React from 'react'
import { approveInvitation, dismissInvitation, getInvitationApprovalStatus } from '@/services/api/userdata.service';
import { useEffect, useState } from 'react';
import API_STATES from '@/constants/StateConstants';
import generalStyles from '@/components/General/Styles.module.scss';
import styles from './UserConfig.module.scss'
import { handleApiError } from '../General/HandleApiError';
import { Button } from 'antd';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function UserInvitationApproval({ email, nsCompanyId, invitationApprovalStatus: invitationApprovalStatusProp }) {
    const [openInvitationApprovalModal, setOpenInvitationApprovalModal] = useState(false)
    const [openCancelInvitationModal, setOpenCancelInvitationModal] = useState(false)
    const [invitationApprovalStatus, setInvitationApprovalStatus] = useState(invitationApprovalStatusProp)

    const [approveInvitationApiStatus, setApproveInvitationApiStatus] = useState(API_STATES.none)
    const [approveInvitationApiError, setApproveInvitationApiError] = useState('')

    const [dismissInvitationApiStatus, setDismissInvitationApiStatus] = useState(API_STATES.none)
    const [dismissInvitationApiError, setDismissInvitationApiError] = useState('')

    useEffect(() => {
        setInvitationApprovalStatus(invitationApprovalStatusProp)
    }, [invitationApprovalStatusProp])

    async function onConfirmApproveInvitation() {
        setInvitationApprovalStatus(API_STATES.loading);
        const result = await approveInvitation(email, nsCompanyId)
        if (result.status === API_STATES.success) {
            setInvitationApprovalStatus('approved')
            setApproveInvitationApiStatus(API_STATES.success)
            setOpenInvitationApprovalModal(false)
        } else {
            handleApiError(
                result.error,
                setApproveInvitationApiStatus,
                setApproveInvitationApiError
            );
            setTimeout(() => setApproveInvitationApiStatus(API_STATES.none), 6000)
        }
    }

    async function onConfirmCancelInvitation() {
        setDismissInvitationApiStatus(API_STATES.loading);
        const result = await dismissInvitation(email, nsCompanyId);
        if (result.status === API_STATES.success) {
            setOpenCancelInvitationModal(false)
            setDismissInvitationApiStatus(API_STATES.success)
            setInvitationApprovalStatus('dismissed')
        } else {
            handleApiError(
                result.error,
                setDismissInvitationApiStatus,
                setDismissInvitationApiError
            );
            setTimeout(() => setDismissInvitationApiStatus(API_STATES.none), 6000)
        }

    }


    return (
        <div data-test="approve-invitation-component">
            <>
                {invitationApprovalStatus === 'approved' ? (
                    <b className={generalStyles.successMessage}>Invitation domain is approved</b>
                ) : invitationApprovalStatus === 'dismissed' ? (
                    <b className={generalStyles.statusMessage}>Invitation not approved by CSR</b>
                ) : invitationApprovalStatus === 'pending' ? (
                    <>
                        <span className={generalStyles.statusMessage}>The invitation domain needs approval to process invitation</span>
                        <Button className={generalStyles.submitButton} onClick={() => setOpenInvitationApprovalModal(true)} disabled={approveInvitationApiStatus === API_STATES.loading}>Approve Invitation</Button>
                        <Button className={generalStyles.submitButton} onClick={() => setOpenCancelInvitationModal(true)} disabled={dismissInvitationApiStatus === API_STATES.loading}>Dismiss Invitation</Button>
                        {approveInvitationApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>approving invitation...</span>
                        ) : approveInvitationApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>{approveInvitationApiError}</span>
                        ) : approveInvitationApiStatus === API_STATES.success && (
                            <span className={generalStyles.successMessage}>Domain Approved</span>
                        )}
                        {dismissInvitationApiStatus === API_STATES.loading ? (
                            <span className={generalStyles.statusMessage}>Dismissing invitation...</span>
                        ) : dismissInvitationApiStatus === API_STATES.error ? (
                            <span className={generalStyles.errorMessage}>{dismissInvitationApiError}</span>
                        ) : dismissInvitationApiStatus === API_STATES.success && (
                            <span className={generalStyles.successMessage}>Invitation Dismissed</span>
                        )}
                    </>) : (
                    <></>
                )}
            </>
            {openInvitationApprovalModal &&
                <Popup
                    open={openInvitationApprovalModal}
                    onClose={() => setOpenInvitationApprovalModal(false)
                    }
                    modal
                >
                    {() => (
                        <div className={styles.modalContainer}>
                            <b>Do you wish to apporove this user invitation ?</b>
                            <br />
                            <div className={generalStyles.row}>
                                <button className={generalStyles.confirmOperationButton} onClick={()=>onConfirmApproveInvitation()}>Submit</button>
                                <button className={generalStyles.cancelOperationButton} onClick={() => setOpenInvitationApprovalModal(false)}>Cancel</button>
                            </div>
                        </div>)
                    }
                </Popup>
            }
            {openCancelInvitationModal &&
                <Popup
                    className={styles.modalContainer}
                    open={openCancelInvitationModal}
                    onClose={() => setOpenCancelInvitationModal(false)
                    }
                    modal
                >
                    {() => (
                        <div className={styles.modalContainer}>
                            <b>Are you sure, you wish to dismiss this invitation ?</b>
                            <br />
                            <div className={generalStyles.row}>
                                <button className={generalStyles.confirmOperationButton} onClick={()=>onConfirmCancelInvitation()}>Submit</button>
                                <button className={generalStyles.cancelOperationButton} onClick={() => setOpenCancelInvitationModal(false)}>Cancel</button>
                            </div>
                        </div>)
                    }
                </Popup>
            }

        </div>
    )
}

export default UserInvitationApproval