import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import API_STATES from '@/constants/StateConstants';

const http = axios.create({
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

/*
Watches auth token state changes to keep the request header in sync
*/

http.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    }
    return config;
});

export async function getUserPasswordResetStatus(nsUserId){
    try {
        const res = await http.get(`/user/${nsUserId}/passwordResetStatus`);
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function approveInvitation(email, nsCompanyId) {
    try {
        await http.post(`/company/${nsCompanyId}/approve_invitation`, { email });
        return { status: API_STATES.success };
    } catch(error) {
        return { status: API_STATES.error, error: error };
    }
}
export async function dismissInvitation(email, nsCompanyId) {
    debugger
    try {
        await http.post(`/company/${nsCompanyId}/dismiss_invitation`, { email });
        return { status: API_STATES.success };
    } catch(error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getInvitationApprovalStatus(email, nsCompanyId) {
    try {
        const response = await http.get(`/company/${nsCompanyId}/invitation_approval`, { params: { email } });
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function updateUserPasswordResetStatus(nsUserId, resetStatus) {
    try {
        await http.put(`/user/${nsUserId}/passwordResetStatus`, {resetValue: resetStatus });
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function resendVerificationEmail(email) {
    try {
        await http.post(`/user`, { email });
        return { status: API_STATES.success };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getEmailVerifiedInfo(email) {
    try {
        const response = await http.get(`/user/emailVerificationInfo/${email}`);
        // response of the form { emailVerificationLink: string | null; isVerified: boolean }
        return { status: API_STATES.success, data: response.data };
    } catch (error) {
        return { status: API_STATES.error, error };
    }
}

export async function getFirebaseUser(email) {
    try {
        const res = await http.get(`/user/firebaseuser`, { params: { email } });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function deleteByzzerUserFirebaseAuth(email) {
    try {
        const res = await http.delete(`/user/firebaseuser`, { params: { email } });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function getResetPasswordLink(email) {
    try {
        const response = await http.get(`/user/${email}/passwordresetlink`);
        return { status: API_STATES.success, data: response.data};
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}

export async function approveUser(userId, role, teamId, approverId) {
    try {
        const res = await http.patch(`/user/${userId}/approve/`, { role, teamId, approverId });
        return { status: API_STATES.success, data: res.data };
    } catch (error) {
        return { status: API_STATES.error, error: error };
    }
}
